<template>
  <div class="page-not-found">
    <h1>404</h1>
    <h2>Page Not Found</h2>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style scoped lang="scss"></style>
